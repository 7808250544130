"use client";

import clsx from "clsx";
// eslint-disable-next-line @typescript-eslint/no-restricted-imports -- This is the only place where we use NextLink
import NextLink from "next/link";
import type { ForwardedRef, HTMLProps, ReactNode } from "react";
import { forwardRef, useMemo } from "react";
export type LinkProps = HTMLProps<HTMLAnchorElement> & {
  readonly className?: string;
  readonly children: ReactNode;
  readonly href: string;
  readonly tabIndex?: number;
  readonly openInNewTab?: boolean | "determineFromSource";
  readonly testId?: string;
  readonly scroll?: boolean;
  readonly position?: string;
};

// Please refer to the the README file to read about the reasoning behind this component.

// eslint-disable-next-line @typescript-eslint/naming-convention -- components ought to be capitalized
export const Link = forwardRef(({
  children,
  href,
  className,
  tabIndex = 0,
  openInNewTab = false,
  onMouseDown,
  onKeyDown,
  testId,
  scroll = true,
  // Next default: shttps://nextjs.org/docs/app/api-reference/components/link#scroll
  position
}: LinkProps, ref: ForwardedRef<HTMLAnchorElement>) => {
  const target = useMemo(() => {
    if (openInNewTab === "determineFromSource") {
      return /^(http|https):\/\//u.test(href) ? "_blank" : undefined;
    }
    return openInNewTab ? "_blank" : undefined;
  }, [href, openInNewTab]);
  return <NextLink className={clsx("hyphens-manual break-words", className)} data-k5a-pos={position} data-testid={testId} href={href} prefetch={false} ref={ref} scroll={scroll} tabIndex={tabIndex} target={target} onKeyDown={onKeyDown} onMouseDown={onMouseDown}>
        {children}
      </NextLink>;
});