"use client";

import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { RegularButton } from "../Button/components/RegularButton/RegularButton.component";
import { OutlineChevronLeftIcon } from "../Icons/components/HeroIcons/OutlineChevronLeftIcon.component";
import { OutlineChevronRightIcon } from "../Icons/components/HeroIcons/OutlineChevronRightIcon.component";
type PaginationProps = {
  readonly isDisabled?: boolean;
  readonly totalPages?: number | null;
  readonly onPageChange?: (newPage: number) => void;
};
export function Pagination({
  isDisabled = false,
  totalPages = null,
  onPageChange
}: PaginationProps) {
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const router = useRouter();
  const currentPage = Number(searchParams.get("page") ?? "1");
  const hasPreviousPage = currentPage !== 1;
  const hasNextPage = totalPages === null || currentPage < totalPages;
  const handlePageChange = (newPage: number) => {
    const newSearchParams = new URLSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      page: newPage.toString()
    });
    router.push(`${pathname}?${newSearchParams.toString()}`);
    onPageChange?.(newPage);
  };
  return <div className="mt-4 flex w-full justify-center gap-0.5" data-sentry-component="Pagination" data-sentry-source-file="Pagination.component.tsx">
      <RegularButton className="rounded-none rounded-l-sm" disabled={isDisabled || !hasPreviousPage} onClick={() => handlePageChange(currentPage - 1)} data-sentry-element="RegularButton" data-sentry-source-file="Pagination.component.tsx">
        <OutlineChevronLeftIcon className="h-2 w-2" data-sentry-element="OutlineChevronLeftIcon" data-sentry-source-file="Pagination.component.tsx" />
      </RegularButton>
      <RegularButton className="rounded-none rounded-r-sm" disabled={isDisabled || !hasNextPage} onClick={() => handlePageChange(currentPage + 1)} data-sentry-element="RegularButton" data-sentry-source-file="Pagination.component.tsx">
        <OutlineChevronRightIcon className="h-2 w-2" data-sentry-element="OutlineChevronRightIcon" data-sentry-source-file="Pagination.component.tsx" />
      </RegularButton>
    </div>;
}